import replace from "string-replace-to-array";
import juration from "./utils/juration";

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function formatPrice(
  p,
  { short, implicit, currency, tabularWidth } = {
    short: false,
    implicit: false,
    currency: "usd"
  }
) {
  if (["krw", "clp", "jpy"].includes(currency)) {
    p *= 100;
    short = true;
  }

  let formatted = (p / 100).toLocaleString("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2
  });

  if (short) {
    // Strip trailing .00 from price, leaving just $XYZ
    formatted = formatted.replace(/\.00$/, "");
  }

  if (implicit) {
    formatted = formatted.replace(/^(CA|MX)/, "");
  }

  if (currency === "cad") {
    formatted = formatted.replace(/^CA\$/, "C$");
  }

  if (tabularWidth) {
    while (formatted.length <= tabularWidth) {
      formatted = formatted.slice(0, 1) + " " + formatted.slice(1); // U+2007 FIGURE SPACE
    }
  }

  return formatted;
}

export function formatShortDuration(duration) {
  return juration.stringify(duration / 1000, {
    format: "short",
    units: 1
  });
}

export function formatNameAvatarText(name = "") {
  const pieces = name.split(" ");
  const selected =
    pieces.length <= 3
      ? pieces
      : pieces.slice(0, 2).concat([pieces[pieces.length - 1]]);

  return selected
    .map(x => x[0])
    .join("")
    .toUpperCase();
}

export function updatePriceWithCouponPriceResult(price, priceResult) {
  if (!priceResult.max_quantity) {
    priceResult.max_quantity = Number.MAX_SAFE_INTEGER;
  }

  // If a coupon is restricted in quantity, only apply the percent_off discount
  // to the relative percent of the discount to which the discount should apply.
  var percentToDiscount = 1;
  if (
    priceResult.max_quantity &&
    priceResult.current_quantity > priceResult.max_quantity
  ) {
    percentToDiscount = priceResult.max_quantity / priceResult.current_quantity;
  }

  if (priceResult.percent_off) {
    price -= price * (priceResult.percent_off / 100) * percentToDiscount;
  }

  if (priceResult.cents_off) {
    price -= priceResult.cents_off;
  }

  if (priceResult.set_price) {
    price = priceResult.set_price * priceResult.current_quantity;
  }

  if (priceResult.cents_off_item) {
    price -=
      priceResult.cents_off_item *
      Math.min(priceResult.max_quantity, priceResult.current_quantity);
  }

  if (price < 0) {
    price = 0;
  }

  return price;
}

export function updatePriceWithGiftCardResult(price, giftCardBalance) {
  price = price - giftCardBalance;

  if (price < 0) {
    price = 0;
  }

  return price;
}

export function formatInterval(ms) {
  const HOUR_DISPLAY_CUTOFF = 1000 * 60 * 60 * 72; // <= 72 hours = show as hours
  const formatted = juration.stringify(ms / 1000, {
    format: "long",
    units: 2,
    max_units: ms <= HOUR_DISPLAY_CUTOFF ? "hours" : undefined
  });
  return formatted;
}

export function formatBrowserTimezone(date, tenant) {
  try {
    const chunks = new Date(date || Date.now())
      .toLocaleTimeString([], { timeZoneName: "short" })
      .split(" ");

    // Last chunk of locale time string is the timezone
    // (either index=2 for 4:30 pm PT or index=1 for 19:20 PT )
    let res = chunks[chunks.length - 1];

    // Format Panama EST => GMT-5
    if (tenant?.locale === "es-PA" && res === "EST") {
      res = "GMT-5";
    }

    return res;
  } catch (e) {
    return "";
  }
}

export function getIpForRequest(req) {
  const ip =
    req.headers["cf-connecting-ip"] ||
    req.headers["x-forwarded-for"] ||
    req.connection?.remoteAddress ||
    req.socket?.remoteAddress;
  return ip ? ip.split(/\s*,\s*/)[0] : null;
}

/**
 * Replaces insertables in the form {{inserable_name|arg1|arg2|arg3...}} with whatever
 * the associated insertables[insertable_name] function returns when called with the arguments.
 * Currently only supports up to 3 arguments.
 */
export function formatInsertables(text, insertables) {
  // string-replace-to-array does not support an empty string
  if (!text) {
    return "";
  }

  let replaced = text;

  Object.keys(insertables).forEach(k => {
    replaced = replace(
      replaced,
      new RegExp(
        "{{" + k + "(?:\\|([^|]*?))?(?:\\|([^|]*?))?(?:\\|([^|]*?))?}}",
        "g"
      ),
      function () {
        return insertables[k].apply(
          null,
          Array.prototype.slice.call(arguments, 1)
        );
      }
    );
  });

  return replaced;
}

let cachedSupportsCustomScrollbars = undefined;
export function supportsCustomScrollbars() {
  if (typeof window === "undefined") {
    return false;
  }
  if (typeof cachedSupportsCustomScrollbars !== "undefined") {
    return cachedSupportsCustomScrollbars;
  }

  let test = document.createElement("div");
  test.className = "__sb-test";
  test.style.overflow = "scroll";
  test.style.width = "40px";

  let style = document.createElement("style");
  style.innerHTML = ".__sb-test::-webkit-scrollbar { width: 0px; }";

  document.body.appendChild(test);
  document.body.appendChild(style);

  let ret = test.scrollWidth == 40;

  document.body.removeChild(test);
  document.body.removeChild(style);

  cachedSupportsCustomScrollbars = ret;
  return ret;
}
